edit-exam-score-modal {
    .edit-exam-score-modal {
        .sub-text-container {
            font-size: 14px;
            text-align: center;
            padding-bottom: 10px;
        }

        form {
            margin-top: 20px;

            table.lesson-scores-table {
                margin-bottom: 30px;

                .score,
                .adjustment-points {
                    text-align: center;
                }

                tbody {
                    td {
                        vertical-align: middle;

                        &.score {
                            &.invalid-score {
                                color: $COLOR_V3_RED;
                            }
                        }

                        &.adjustment-points {
                            input {
                                width: 75px;
                            }
                        }
                    }
                }
            }

            .form-errors {
                color: $COLOR_V3_RED;
            }
        }

        .success-page {
            padding-top: 20px;

            .message {
                margin-top: 20px;
                margin-bottom: 10px;
            }

            .sub-text-container {
                font-style: italic;
                margin-bottom: 10px;
            }
        }
    }
}
