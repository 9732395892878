@import "oreo/global/colors_v3";
@import "oreo/global/fonts";
@import "oreo/global/animations";

div.admin-create-user {
    $nav-bar-width: 220px;
    $modal-height: 750px;

    $APP_HEADER_HEIGHT: 75px;
    $APP_HEADER_HEIGHT_MOBILE: 40px;
    $mobile_nav_toggle_btn_height: 40px;
    $mobile_bottom_navbar_height: 55px;

    position: absolute;
    left: 16px;
    top: 25px;

    div.create-user-modal-overlay {
        animation: fade-in 0.3s;
        &.close-modal {
            animation: fade-out 0.3s;
        }

        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1000;
    }

    div.create-user-success {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        height: 56px;
        font-size: 18px;
        padding: 15px;
        border-radius: 5px;
        background-color: $COLOR_V3_GREEN;
        color: $COLOR_V3_WHITE;

        left: calc(50% + $nav-bar-width/2);
        top: 8.5%;
        transform: translate(-50%, 0);
        @media (max-width: 991px) {
            left: 50%;
        }
    }

    div.create-user-modal {
        animation: fade-in 0.3s;
        &.close-modal {
            animation: fade-out 0.3s;
        }

        @media (max-width: 767px) {
            left: 50%;
            top: calc($APP_HEADER_HEIGHT_MOBILE + 5vh);
            max-height: calc(93vh - $APP_HEADER_HEIGHT_MOBILE - $mobile_bottom_navbar_height);
            max-width: 98vw;
        }
        @media (min-width: 767px) and (max-width: 991px) {
            left: 50%;
        }

        padding: 10px;
        position: fixed;
        width: 800px;
        height: 810px;
        max-height: calc(90vh - $APP_HEADER_HEIGHT);

        top: calc($APP_HEADER_HEIGHT + 5vh);
        left: calc(50% + $nav-bar-width/2);
        transform: translate(-50%, 0);
        display: flex;

        flex-direction: column;
        background-color: $COLOR_V3_WHITE;
        z-index: 1000;
        border-radius: 10px;
        overflow: hidden;

        > button {
            &:first-of-type {
                position: fixed;
                top: 0;
                right: 0;
            }
            &:last-of-type {
                min-width: 300px;
                margin: 0 auto;
            }
        }

        > h3 {
            display: flex;
            font-size: 25px;
            justify-content: center;
            height: 50px;
            align-items: center;
        }

        p {
            margin: 0 20px 10px 20px;
            font-size: 14px;
        }

        .create-user-modal-body {
            display: flex;
            box-sizing: border-box;
            overflow-y: auto;
            margin: 0 0 5px 0;
            > div {
                display: flex;
                flex-direction: column;
                flex: 0 0 50%;
                padding: 5px 20px 0 20px;
                max-width: 400px;

                &:last-of-type {
                    > div:nth-last-child(-n + 4) {
                        > label {
                            margin-bottom: 0;
                        }
                    }
                }

                > div {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
