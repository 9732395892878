.admin-table {
    word-wrap: break-word;

    &.fixed {
        table-layout: fixed;
    }

    .sortable {
        &:after {
            @include font-awesome-icon("\f0dc"); // fa-sort
            color: $COLOR_V3_BEIGE_LIGHT;
            padding-left: 6px;
        }

        &.current {
            &:after {
                color: $COLOR_V3_BLACK;
            }

            &.asc:after {
                @include font-awesome-icon("\f0dd"); // fa-sort-down
            }

            &.desc:after {
                @include font-awesome-icon("\f0de"); // fa-sort-up
            }
        }
    }
}

.fixed-table-container {
    $headerHeight: 50px;

    width: 100%;
    height: 300px;
    margin: 10px auto;
    position: relative;
    padding-top: 15px;

    .header-background {
        background-color: $COLOR_V3_BEIGE_LIGHTER;
        height: $headerHeight;
        position: absolute;
        top: 0;
        right: 10px;
        left: 0;
    }

    .fixed-table-container-inner {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
    }

    table {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        thead {
            .th-inner-first,
            .th-inner-second {
                position: absolute;
                top: 0;
                line-height: $headerHeight * 0.5;
                text-align: left;
                padding-left: 5px;
                margin-left: -5px;
                border: none;

                .first & {
                    padding-left: 6px;
                }
            }

            .th-inner-second {
                top: $headerHeight * 0.5;
            }
        }
    }
}

.lesson-permission-cols {
    th,
    td {
        width: 10%;
        &:nth-child(2) {
            width: 50%;
        }
    }
}
