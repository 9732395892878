admin-edit-student-enrollment-documents {
    .uploaded-documents-container {
        padding-top: 0px;

        .multiple-files {
            .file-info-container {
                float: unset;
                width: 100%;
            }
        }
    }

    .upload {
        margin-top: 10px;
    }

    .comments-section {
        margin-top: 10px;

        textarea {
            width: 100%;
        }
    }
}
