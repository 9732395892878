$aoi_model_color: $COLOR_V3_CORAL;
$aoi_model_color_dark: $COLOR_V3_CORAL_DARK;
$enrollment_color: $COLOR_V3_GREEN;
$enrollment_color_dark: $COLOR_V3_GREEN_DARK;
$career_profile_color: $COLOR_V3_BLUE;
$career_profile_color_dark: $COLOR_V3_BLUE_DARK;
$project_color: $COLOR_V3_PURPLE;
$project_color_dark: $COLOR_V3_PURPLE_DARK;
$student_network_color: $COLOR_V3_TURQUOISE;
$student_network_color_dark: $COLOR_V3_TURQUOISE_DARK;
$exam_color: $COLOR_V3_GREY;
$exam_color_dark: $COLOR_V3_GREY_DARK;
$note_color: $COLOR_V3_YELLOW;
$note_color_dark: $COLOR_V3_YELLOW_DARK;
$user_management_action_a_color: $COLOR_V3_GREEN;
$user_management_action_o_color: $COLOR_V3_TURQUOISE;
$user_management_action_i_color: $COLOR_V3_CORAL;
$user_management_action_u_color: $COLOR_V3_GREY;

activity-timeline {
    .note-container {
        textarea {
            width: 100%;
            height: 48px;
            min-height: 48px;
        }
    }

    .timeline {
        color: $COLOR_V3_GREY_DARKER;
        font-size: 16px;
        line-height: 22px;
        margin-top: 30px;

        .item {
            @include clearfix;

            &:last-child .inner-content {
                min-height: 44px;
            }

            &.spacer .content {
                padding-bottom: 50px;
            }
        }

        .info {
            width: 200px;
            float: left;
            text-align: right;
            padding: 2px 20px 0 0;
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                border: 2px solid;
                border-radius: 50%;
                background: $COLOR_V3_WHITE;
                position: absolute;
                top: 4px;
                right: -9px;
            }

            .category {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 12px;
                text-transform: uppercase;
            }

            .timestamp {
                font-size: 14px;

                .time {
                    color: $COLOR_V3_GREY_DARK;
                    padding-left: 5px;
                }
            }
        }

        .content {
            width: calc(100% - 200px);
            float: left;
            border-left: 2px solid $COLOR_V3_GREY_LIGHTER;
            padding: 0 0 0 20px;
            user-select: text;

            .inner-content {
                min-height: 60px;
                padding-bottom: 10px;
            }

            .label,
            .from,
            .to,
            .text.performed_action,
            .text.executed_user_management_action {
                border-radius: 4px;
                display: inline-block;
                margin-right: 8px;
                padding: 1px 5px 0;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 12px;
                text-transform: uppercase;
                color: $COLOR_V3_WHITE;
                line-height: 15px;
                vertical-align: middle;
            }

            .from,
            .to,
            .text.performed_action {
                margin-left: 8px;
            }

            .from {
                background: $COLOR_V3_GREY_LIGHT;
            }

            .to,
            .text.performed_action {
                background: $COLOR_V3_GREY;
            }

            .attribute {
                font-weight: $FONT_WEIGHT_SEMIBOLD;
            }

            .text {
                max-width: 650px;
            }

            .editor {
                color: $COLOR_V3_GREY_DARK;
                font-size: 14px;
                padding-top: 2px;
                display: table;

                .remove-note {
                    margin: -3px 0 0 8px;

                    &:disabled {
                        opacity: 0.6;
                    }
                }
            }

            .editor-event-container {
                display: inline-block;
            }

            ul.user-management-action-param-list {
                display: inline-grid;
                padding-left: 0;
                margin-left: 3px;

                li.label {
                    width: fit-content;
                    margin-bottom: 5px;
                }
            }

            .download-document {
                cursor: pointer;
            }
        }
    }

    .program_application,
    .admission_offer,
    .program_inclusion,
    .tuition_contract {
        .category {
            color: $aoi_model_color;
        }

        .info::before {
            border-color: $aoi_model_color;
        }

        .label {
            background: $aoi_model_color;
        }

        .attribute {
            color: $aoi_model_color_dark;
        }
    }

    .enrollment {
        .category {
            color: $enrollment_color;
        }

        .info::before {
            border-color: $enrollment_color;
        }

        .label {
            background: $enrollment_color;
        }

        .attribute {
            color: $enrollment_color_dark;
        }
    }

    .career_profile {
        .category {
            color: $career_profile_color;
        }

        .info::before {
            border-color: $career_profile_color;
        }

        .label {
            background: $career_profile_color;
        }

        .attribute {
            color: $career_profile_color_dark;
        }
    }

    .project {
        .category {
            color: $project_color;
        }

        .info::before {
            border-color: $project_color;
        }

        .label {
            background: $project_color;
        }

        .attribute {
            color: $project_color_dark;
        }
    }

    .student_network {
        .category {
            color: $student_network_color;
        }

        .info::before {
            border-color: $student_network_color;
        }

        .label {
            background: $student_network_color;
        }

        .attribute {
            color: $student_network_color_dark;
        }
    }

    .exam {
        .category {
            color: $exam_color;
        }

        .info::before {
            border-color: $exam_color;
        }

        .label {
            background: $exam_color;
        }

        .attribute {
            color: $exam_color_dark;
        }
    }

    .note {
        .category {
            color: $note_color;
        }

        .info::before {
            border-color: $note_color;
        }

        .label {
            background: $note_color;
        }

        .attribute {
            color: $note_color_dark;
        }
    }

    .executed_user_management_action {
        .label,
        .text.executed_user_management_action {
            text-transform: none !important;
            font-size: 13px !important;
        }

        .subtext {
            margin-right: 8px;
        }

        &.program_application {
            .category {
                color: $user_management_action_a_color;
            }

            .text.executed_user_management_action {
                background: $user_management_action_a_color;
            }

            .info::before {
                border-color: $user_management_action_a_color;
            }

            .label {
                background: $user_management_action_a_color;
            }
        }

        &.admission_offer {
            .category {
                color: $user_management_action_o_color;
            }

            .text.executed_user_management_action {
                background: $user_management_action_o_color;
            }

            .info::before {
                border-color: $user_management_action_o_color;
            }

            .label {
                background: $user_management_action_o_color;
            }
        }

        &.program_inclusion {
            .category {
                color: $user_management_action_i_color;
            }

            .text.executed_user_management_action {
                background: $user_management_action_i_color;
            }

            .info::before {
                border-color: $user_management_action_i_color;
            }

            .label {
                background: $user_management_action_i_color;
            }
        }

        &.user {
            .category {
                color: $user_management_action_u_color;
            }

            .text.executed_user_management_action {
                background: $user_management_action_u_color;
            }

            .info::before {
                border-color: $user_management_action_u_color;
            }

            .label {
                background: $user_management_action_u_color;
            }
        }
    }
}
