admin-edit-curriculum-template {
    .form-group {
        padding: 0px !important;
        margin: 0px -15px 20px !important;
    }

    .edit-curriculum-form {
        padding-top: 40px;
    }
}
