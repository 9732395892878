admin-edit-cohort {
    .form-group {
        padding: 0px !important;
        margin: 0px -15px 20px !important;
    }

    .edit-cohort-form {
        padding-top: 40px;
    }

    .saved-and-published-at {
        margin-bottom: 5px;
    }

    .import-curriculum-template-box {
        position: relative;
        top: -95px;
        margin-bottom: -45px;
    }
}
