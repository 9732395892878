lesson-permissions {
    .form-group {
        margin-bottom: 4px;

        .form-text {
            line-height: 36px;
        }
        .sub-text {
            display: block;
            font-size: 13px;
            line-height: 14px;
            font-style: $FONT_STYLE_ITALIC;
        }
    }
    tel-input input,
    tel-input select {
        height: 36px;
    }

    .documentation-link {
        text-decoration: underline;
        cursor: pointer;
    }

    @include set-selectize-general();

    selectize.ng-invalid + .selectize-control {
        .selectize-input {
            border-color: $COLOR_V3_RED !important;
        }
    }
    input.ng-invalid {
        border-color: $COLOR_V3_RED;
    }

    textarea {
        border: none;
        margin-bottom: 4px;
        &[disabled="disabled"] {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }

    select-with-other input {
        margin-bottom: 10px;
    }

    .form-actions button {
        margin-left: 10px;
    }

    .navbar-form {
        margin-bottom: 20px;
    }

    .num-assigned-lessons {
        font-size: 12px;
        font-weight: 600;
        color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        background: $COLOR_V3_BEIGE_MIDDARK;
        border-radius: 20px;
        padding: 3px 7px;
        margin-left: 5px;
    }

    .fixed-table-container,
    .no-lessons {
        margin: 0;
    }

    .no-lessons {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex: 1 1 auto;
    }

    .fixed-table-container {
        flex: 1 1 auto;

        $headerBackgroundHeight: 70px;
        padding-top: $headerBackgroundHeight - 1px;

        .header-background {
            height: $headerBackgroundHeight;
            right: 0px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid #ddd;
        }

        button.select-all {
            font-size: 8px;
            padding: 3px;
            line-height: 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 700;
        }

        table {
            margin-bottom: 0;
        }

        thead th,
        thead td {
            height: 0;
            margin: 0;
            padding: 0;
            border: none;

            .th-inner-first,
            .th-inner-second {
                padding-left: 13px;
            }

            .th-inner-first {
                top: 10px;

                &:hover {
                    cursor: pointer;
                }

                // One of the roles for lesson permissions is "hidden", which adds a "hidden" class to one of our table headers.
                // Overwriting that class here so that the content isn't being hidden via the higher-level "hidden" class.
                &.hidden {
                    display: block !important;
                }
            }

            .th-inner-second {
                top: 35px;
            }
        }
    }

    .filter-bar {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        border-radius: 0px;
        justify-self: flex-start;

        .input-wrapper {
            min-height: auto;
            height: 31px;
        }

        .total-results {
            font-size: 14px;
            color: $COLOR_V3_BEIGE_DARKER;
            margin-bottom: 0;
            margin-left: 10px;
            font-weight: 600;
        }
    }

    .tab-content {
        border: 1px solid #ddd;
        border-radius: 0 0 4px 4px;
        margin-top: -1px;
        height: 550px;

        .tab-pane {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }
    }
}

.groups-admin,
.institutions-admin,
.cohorts-admin {
    .small {
        font-size: 10px;
    }

    user-select: text !important;

    .form-inline .form-group {
        margin: 5px 5px;
        height: 42px;

        &:first-child {
            margin: 5px 5px 5px 0px;
        }

        &.group {
            .selectize-control {
                width: 200px;
            }
        }

        &.user_id {
            .selectize-control {
                width: 300px;
            }
        }
    }

    .dropdown-input-form {
        .form-group {
            margin: 10px !important;
        }
        width: 225px;
    }

    .additional-details {
        cursor: context-menu;
        height: 35px;
        line-height: 35px;
    }
}

list-applicants .label {
    &.applied,
    &.offered_admission {
        background-color: $COLOR_V3_BLUE;
    }

    &.not_offered_admission,
    &.offer_expired {
        background-color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    &.registered {
        background-color: $COLOR_V3_TURQUOISE;
    }

    &.matriculated,
    &.graduated,
    &.with_honors {
        background-color: $COLOR_V3_GREEN;
    }

    &.withdrawn,
    &.expelled,
    &.failed {
        background-color: $COLOR_V3_RED;
    }

    &.deferred {
        background-color: $COLOR_V3_YELLOW;
    }
}

batch-edit-users .label {
    // Statuses
    &.accepted {
        background-color: $COLOR_V3_GREEN;
    }

    &.pending {
        background-color: $COLOR_V3_BEIGE;
    }

    &.deferred {
        background-color: $COLOR_V3_YELLOW;
    }

    &.rejected,
    &.expelled,
    &.rejected_or_expelled {
        background-color: $COLOR_V3_CORAL;
    }

    &.pre_accepted {
        background-color: $COLOR_V3_TURQUOISE;
    }

    // Graduation statuses
    &.failed {
        background-color: $COLOR_V3_CORAL;
    }

    &.graduated {
        background-color: $COLOR_V3_GREEN;
    }

    &.honors {
        background-color: $COLOR_V3_PURPLE;
    }
}

batch-edit-users .label {
    &.pending {
        color: $COLOR_V3_BLACK;
    }
}

.global-metadata-admin {
    user-select: text !important;

    .short {
        width: 110px;
    }
    .images {
        width: 70px;
    }
    textarea.form-control {
        resize: none;
        height: 200px;
    }
}

// Overrides necessary for lesson-permissions to reuse some
// styles that were originally created for User Admin 1.0.
lesson-permissions {
    .form-group {
        display: table;
    }
    .tab-pane {
        .filter-bar {
            .input-wrapper {
                margin-right: 5px;

                .input-group {
                    .input-group-addon {
                        min-width: 1px !important;
                    }
                    input[type="text"] {
                        padding: 6px 12px;
                        height: 31px;
                        border-color: rgb(169, 161, 151);
                    }
                }
                .selectize-control:not(.wide) {
                    width: 160px;
                }

                .selectize-dropdown .option-icon,
                .selectize-dropdown .option {
                    height: auto;
                    padding: 8px 20px;
                }
            }
        }
    }
    .table {
        td {
            color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
        }
    }
}
