batch-edit-users {
    h2 {
        margin-bottom: 18px;
    }

    textarea[name="user-identifiers"] {
        width: 100%;
        height: 120px;
    }

    .warning {
        color: $COLOR_V3_YELLOW;
    }

    .error,
    .control-title.error {
        color: $COLOR_V3_RED;

        i {
            animation: pulse 1s ease-out infinite;
        }
    }

    .messages {
        > div {
            margin-bottom: 20px;
        }
    }

    .table > tbody > tr {
        user-select: all !important;

        &.error-row .error {
            padding-left: 30px;
        }

        &.error-row,
        &.error-row td {
            border-top: 0px;
            background-color: $COLOR_V3_RED_LIGHT;
        }

        &.user-row.has-error,
        &.user-row.has-error td {
            border-top: 0px;
            background-color: $COLOR_V3_RED_LIGHT;
        }
    }
}
