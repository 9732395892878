.floating-button-box {
    display: block;
    z-index: 1000;
    position: relative;
    margin-bottom: 15px;
    clear: both;

    &.prev-next-box {
        position: absolute;
        right: 0px;
        bottom: calc(100% + 22px);

        button {
            padding: 10px 5px;
        }
    }

    button,
    span {
        display: inline-block;
        vertical-align: middle;
    }
    span.sub-text {
        margin-bottom: 0px;
        width: 260px;
        text-align: right;
        margin-right: 10px;
    }

    .button-with-sub-text button {
        margin-right: 5px;
    }
}
