admin-owner-payments {
    .front-royal-form-container {
        position: relative;

        .view-stripe-link {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 1;
        }
    }

    .label {
        &.complete {
            background-color: $COLOR_V3_GREEN;
        }

        &.canceled {
            background-color: $COLOR_V3_GREY;
        }

        &.open {
            background-color: $COLOR_V3_TURQUOISE;
        }
    }
}
