admin-edit-career-profile {
    $full-width-break-point: 991px;
    user-select: text;

    .related-info {
        @media (max-width: $full-width-break-point) {
            margin-top: 30px;
        }
    }

    .confirm-content-and-status {
        margin-top: 20px;
        margin-bottom: 20px;
        float: right;

        button {
            white-space: normal;
        }
    }

    .tab-pane-career-network {
        select,
        textarea {
            margin-top: 5px;
        }

        .contact {
            p {
                &.sub-text {
                    margin-bottom: 0px;
                }
            }

            button {
                margin-top: 10px;
            }
        }

        .connections {
            padding-top: 15px;
        }
    }

    .edit-profile-btn-group-container {
        text-align: center;

        .btn-group {
            text-align: left;
        }
    }

    .tab-pane-student-records {
        .edit-documents-container {
            margin: 0 auto;
            position: relative;
            max-width: 800px;
        }
    }

    .form-group.row & {
        .experience-detail.admin {
            padding: 10px 20px 20px 20px;

            .gpa-container {
                padding-bottom: 20px;
            }

            .year-container[disabled="disabled"],
            .detail-input[disabled="disabled"] {
                select,
                p,
                label,
                input {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }

            .detail-input {
                .degree-select,
                select,
                input {
                    width: 100%;
                }
            }
        }
    }
}
