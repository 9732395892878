list-applicants {
    $dateColumnWidth: 130px;

    editable-things-list {
        table {
            th.created_at {
                width: $dateColumnWidth;
            }
        }
    }

    .front-royal-spinner {
        margin: 0 auto;
    }

    .filters-section-title {
        color: $COLOR_V3_BEIGE_DARKEST;
    }

    .filter-bar .input-wrapper {
        margin-right: 0;

        .input-group .input-group-addon {
            min-width: 100px;
        }
    }

    .filter-form,
    .advanced-filters-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3px 6px;

        .form-buttons {
            grid-column: 1 / span 1;

            button {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        .filter-form {
            grid-template-columns: 1fr 1fr 1fr;

            .advanced-filters-container {
                grid-template-columns: 1fr 1fr 1fr;
                grid-column: 1 / span 3;
            }

            h3 {
                grid-column: 1 / span 3;
            }

            .form-buttons {
                grid-column: 1 / span 3;
                text-align: right;

                button {
                    width: auto;

                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        .filter-form {
            grid-template-columns: 1fr 1fr 1fr 1fr;

            .advanced-filters-container {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-column: 1 / span 4;
            }

            h3 {
                grid-column: 1 / span 4;
            }

            .form-buttons {
                grid-column: 1 / span 4;

                button[name="toggle-filters"] {
                    float: left;
                }
            }
        }
    }

    .edit-content-list-view {
        button[name="search"],
        button[name="reset"],
        button[name="toggle-filters"] {
            margin-left: 0;
        }

        .input-group,
        .selectize-control,
        .datepicker-container {
            width: 100%;
        }
    }
}
