admin-edit-student-transcripts {
    .eligible-degree-container {
        margin: 10px 0;
    }

    .transcript-override-wrapper {
        margin-bottom: 25px;
    }

    .uploaded-documents-container {
        .transcript-upload-container {
            display: inline-block;
            border-top: 1px solid black;
            padding-top: 20px;
            margin-bottom: 32px;
            width: 100%;

            .degree-label span {
                font-size: 18px;
                font-weight: $FONT_WEIGHT_MEDIUM;
            }

            .upload-button {
                margin-top: 12px;
            }

            .transcript-status {
                select[name="transcript-status"] {
                    margin-bottom: 10px;
                }
            }

            .input-container {
                margin-bottom: 10px;
            }

            .notes-for-admissions-container {
                label {
                    display: inline-block;
                }

                .toggle-notes-for-admissions {
                    color: $COLOR_V3_BLUE;
                    cursor: pointer;
                    font-size: 14px;
                    margin-left: 3px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                textarea[name="notes-for-admissions"] {
                    margin-bottom: 10px;
                    width: 100%;
                }
            }

            .transcript-required-text {
                opacity: 0.5;
            }
        }
    }

    .transcript-files {
        float: left;
        width: 100%;

        @media (max-width: $screen-xs-max) {
            label {
                padding-top: 0;
            }
        }

        .transcript-type-select {
            margin: 0px !important;
        }

        .file-info-container {
            margin: 0px;
        }
    }
}
