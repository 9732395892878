.front-royal-form-field-row {
    display: flex;
    margin: 15px;
    align-items: center;
    flex-direction: column;

    > div {
        max-width: 500px;
        &.sub-text {
            padding-left: 8px;
            justify-self: start;
            width: 100%;
            margin: 0;
        }

        &.refund-editor {
            margin-bottom: 5px;
        }
    }

    input[type="text"],
    textarea {
        border: none;
        &:focus {
            border: none;
        }
    }

    .error-message {
        /*
            We want the position to be absolute
            so that things don't move on the screen when the message
            shows up.

            It would seem that we should have to set the `left` value,
            but it works without it, and that way we don't have to
            worry about the 15px in padding that bootstrap adds.
        */
        position: absolute;
        top: 100%;
    }
}
