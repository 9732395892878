// we have to wrap this in edit-schedule so we can override styles from schedule-table
.edit-schedule {
    edit-cohort-sections-and-slack-rooms {
        .section-slack-wrapper:not(:last-child) {
            margin-bottom: 20px;
        }

        .section-wrapper {
            margin: 0 12px;

            &.editing {
                padding: 10px 0px;
                border: 2px solid $COLOR_V3_BLUE;
                border-radius: 4px;

                .pull-right {
                    button {
                        margin-left: 8px;
                    }
                }
            }
        }

        .add-section-button {
            padding: 3px 10px;
            width: 100%;
            border: 1px solid $COLOR_V3_BEIGE_DARK;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;

            &:hover,
            &:active {
                background-color: $COLOR_V3_BLUE;
                border: 1px solid $COLOR_V3_BLUE;
                color: $COLOR_V3_WHITE;
            }

            // If we don't do this, then right after clicking the text fades
            // to white and we can't see it
            &:focus {
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
            }
        }

        .slack-editing-message {
            color: $COLOR_V3_BLACK;
            font-size: 16px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            text-align: center;
            margin: 15px 0;
        }

        table {
            width: 100%;
        }

        td {
            padding: 4px;

            .deprecation-notice {
                font-size: 13px;
            }

            a[name="add-to-slack-button"] {
                margin-right: 5px;
                text-decoration: none;

                &.disabled {
                    cursor: not-allowed;
                }

                & + span > strong {
                    color: $COLOR_V3_GREEN_DARK;
                }
            }
        }

        td.label-cell {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            text-align: right;
            width: 125px;
        }

        td input {
            width: 100%;
        }

        button.add-slack-room {
            margin: 0px 5px;
            padding: 3px 10px;
            display: block;
            width: calc(100% - 10px);
            border: 1px solid $COLOR_V3_BEIGE_DARK;
            color: $COLOR_V3_BEIGE_BEYOND_DARK;

            &:hover,
            &:active {
                background-color: $COLOR_V3_BLUE;
                border: 1px solid $COLOR_V3_BLUE;
                color: $COLOR_V3_WHITE;
            }
        }

        table.section-table {
            margin-bottom: 0;

            > tbody {
                > tr {
                    margin-bottom: 10px;

                    > td {
                        vertical-align: top;
                        display: inline-block;
                        border: none;
                        padding: 0;
                    }

                    > td.section-column,
                    td.section-header {
                        color: $COLOR_V3_BLACK;
                        width: 150px;
                    }

                    > td.slack-column,
                    td.slack-header {
                        width: calc(100% - 150px);
                    }

                    > td.section-header,
                    td.slack-header {
                        color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: $FONT_WEIGHT_SEMIBOLD;
                        padding-bottom: 0;
                        margin-bottom: 10px;
                    }

                    > td.full-width-column {
                        width: 100%;
                    }
                }
            }
        }
    }
}
