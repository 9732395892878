admin-student-records {
    .header {
        text-decoration: underline;
    }

    .file-name {
        cursor: pointer;
    }

    .disabled {
        opacity: 0.5;

        [disabled] {
            // any disabled element inside of the ancestor element that has the disabled CLASS applied
            // should have a normal opacity level of 1 to prevent it from being too blurred out.
            opacity: 1 !important;
        }

        .admin-message {
            color: #333333; // makes the admin message more easily visible
        }
    }

    .verified {
        text-align: center;
        margin-bottom: 15px;

        .box {
            display: inline-block;
            max-width: 100%;
            border: 1px solid $COLOR_V3_TURQUOISE;
            border-radius: 4px;
            padding: 20px;
        }

        .sub-text {
            color: $COLOR_V3_TURQUOISE !important;
        }

        img {
            width: 40px;
            display: inline-block;
            margin-right: 5px;
        }
    }

    .uploaded-documents-container {
        padding-top: 10px;
    }

    .uploaded-documents-label {
        color: $COLOR_V3_GREY_LIGHT;
        display: flex;
        align-items: center;

        h6 {
            flex: 0 0 auto;
        }

        @media (max-width: $screen-xs-max) {
            &::before,
            &::after {
                content: "";
                height: 2px;
                width: 100%;
                background: $COLOR_V3_GREY_LIGHTER;
                border-radius: 10px;
                flex: 1 1 auto;
            }

            &::before {
                margin: 0 10px 2px 0;
            }

            &::after {
                margin: 0 0 2px 10px;
            }
        }

        &.no-documents {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}
