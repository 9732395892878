edit-period-exercise {
    [name="message"],
    [name="channel"],
    admin-datetimepicker > div,
    admin-datetimepicker button {
        width: 100%;
    }

    admin-datetimepicker button {
        height: 48px;
    }

    .row[name="exercise-documents-container"] {
        margin: 0;
        padding-bottom: 15px;

        .duplicate-button {
            margin-top: 5px;
        }
    }

    .checkbox-wrapper {
        margin-bottom: 12px;

        > span {
            vertical-align: top;
        }
    }
}
