.StudentNetworkEventAccessFields {
    .flex-row {
        display: flex;

        > * {
            flex: 1;
        }
    }

    .flex-column {
        display: flex;
        flex-direction: column;

        > * {
            flex: 1;
        }
    }

    .header-row {
        display: "flex";
    }

    .InstitutionAccessRow {
        padding-top: 12px;
    }

    // Maybe this should be defined at a higher level? Seems like it could
    // be a general rule
    .MuiChip-root {
        margin: 0px 4px 4px;
    }

    .MuiDivider-root {
        margin-bottom: 8px;
    }

    // See the hacks below on MuiCardHeader-root. This makes it so we can see if overlapping the border
    &.MuiCard-root {
        overflow: visible;
    }

    // Make the card header match the labels on the other input fields on the screen.
    .MuiInputLabel-root {
        margin: -7px 0px 0px 9px;
        transform: scale(0.75);
        background: white;
        width: 79px;
        padding-left: 7px;
    }
}
