admin-cohort-gradebook {
    $PROJECT_SCORE_SELECT_WIDTH: 100px;

    editable-things-list {
        tr.thing {
            .exam-score-container {
                position: relative;

                .fa-edit {
                    margin-left: 5px;
                    margin-top: 1px;
                    position: absolute;
                    visibility: hidden;
                }
            }

            &:hover {
                .exam-score-container .fa-edit {
                    @include font-awesome-icon("\f044"); // fa-edit
                    visibility: visible;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            td {
                &.project {
                    position: relative; // so we can position the history tooltip

                    input {
                        width: 3em;
                    }

                    .history-icon {
                        margin: auto 10px;

                        .fa-history {
                            opacity: 0.5;

                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }

                    .history-tooltip {
                        right: 35px;
                        left: inherit;
                    }

                    // Add some helpful color coding to keep the columns apart in the UI
                    &:nth-child(5n) {
                        background: $COLOR_V3_YELLOW_LIGHT;
                    }
                    &:nth-child(5n-4) {
                        background: $COLOR_V3_BLUE_LIGHT;
                    }
                    &:nth-child(5n-3) {
                        background: $COLOR_V3_PURPLE_LIGHT;
                    }
                    &:nth-child(5n-2) {
                        background: $COLOR_V3_TURQUOISE_LIGHT;
                    }
                    &:nth-child(5n-1) {
                        background: $COLOR_V3_GREY_LIGHT;
                    }
                }
            }

            [data-id="project_score"] {
                .warning {
                    color: $COLOR_V3_RED;
                    font-weight: $FONT_WEIGHT_BOLD;
                }

                .strikethrough {
                    text-decoration: line-through;
                }
            }
        }
    }

    .edit-content-list-panel-body.front-royal-form-container {
        select[name="project-score"] {
            width: $PROJECT_SCORE_SELECT_WIDTH !important; // need the important to beat out front-royal-form styles
        }
    }
}
