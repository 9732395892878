admin-edit-organization-option {
    .col-xs-offset-20 {
        margin-left: 20px;
        padding-top: 0px;
    }

    .form-group {
        span {
            word-wrap: break-word;
        }

        img.organization-logo {
            image-rendering: -moz-crisp-edges;
            image-rendering: -o-crisp-edges;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor;
            margin-left: 20px;
            max-width: 200px;
            max-height: 52px;
        }
    }
}
