admin-mba {
    form {
        .section-label {
            color: $COLOR_V3_BEIGE_BEYOND_DARK;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            margin-bottom: 10px;
        }

        .markdown-preview {
            border: 1px dashed $COLOR_V3_BEIGE_DARKEST;
            border-radius: 4px;
            padding: 5px 10px;
            margin: 5px 0px;
            display: block;

            [markdown] {
                > p:last-of-type {
                    margin: 0px;
                }

                a {
                    color: $COLOR_V3_CORAL;
                    text-decoration: underline;
                }
            }
        }

        .markdown-preview.description {
            margin-bottom: 25px;
        }

        textarea {
            &.wide {
                width: 100%;
            }
        }
    }

    .selectize-control {
        &.flexible-height {
            .selectize-input {
                height: auto;
            }
        }
    }

    // These styles are used in edit-schedule, edit-cohort-sections-and-slack-rooms (which
    // is inside of edit-schedule) and edit-project-documents
    .icon.small {
        cursor: pointer;
        margin-right: 2px;
        padding: 5px 10px;
        border: 0px solid transparent;
        border-radius: 4px;
        background-color: $COLOR_V3_BEIGE_BEYOND_DARK;
        color: $COLOR_V3_WHITE;
        font-size: 16px;
        text-align: center;

        &:hover {
            background-color: $COLOR_V3_BLUE;
        }

        &.remove {
            background-color: $COLOR_V3_RED;
            &:hover {
                background-color: $COLOR_V3_RED_DARK;
            }
        }

        &:before {
            margin-left: 0px;
        }
    }
}
