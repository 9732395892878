@import "oreo/global/colors_v3";

.admin-edit-billing-transaction {
    // We want this to take up the same amount of space regardless of whether
    // the message is currently showing or not
    .amount_refunded.error-message {
        position: relative;
        height: 36px;
        position: relative;
        top: auto;
    }

    input.MuiSelect-nativeInput {
        &:disabled {
            display: none;
        }
    }

    .refund-editor {
        border-radius: 4px;
        border: 1px solid $COLOR_V3_BLUE_LIGHT;
        padding: 12px 8px 8px 20px;
        background-color: $COLOR_V3_BLUE_LIGHTER;
        position: relative;

        > div {
            margin-bottom: 8px;
            width: 90%;

            &:last-of-type {
                padding-left: 8px;
            }
        }

        button.remove {
            position: absolute;
            top: 12px;
            right: 8px;
            min-width: auto;
        }
    }
}
