admin-cohort-enrollment-status {
    .edit-content-list-view {
        .save-all-container {
            button[name="save-all"] {
                display: block;
                margin: 0 auto;
            }
        }

        .edit-content-list-panel-header.filter-bar {
            input {
                height: 31px;
            }
        }
    }

    & > .form-actions,
    .editable-things-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    editable-things-list {
        th {
            white-space: normal !important;

            &[data-id="identifiedForEnrollment"],
            &[data-id="hasUploadedTranscripts"],
            &[data-id="transcripts_verified"],
            &[data-id="hasUploadedEnglishLanguageProficiencyDocuments"],
            &[data-id="english_language_proficiency_documents_approved"],
            &[data-id="has_signed_enrollment_agreement"] {
                text-align: center;
            }
        }

        .has-uploaded-english-language-proficiency-documents {
            text-align: center;

            &.disabled {
                opacity: 0.5;
            }
        }

        [data-id="has-signed-enrollment-agreement"] {
            // Since the existence of th eupload link means that they haven't signed the
            // agreement yet, we fade it out
            .fa-upload {
                opacity: 0.2;
            }
        }
    }
}
