admin-view-calendar {
    .fc .fc-button-primary {
        background-color: #4a4a4a !important;
        border-color: #4a4a4a !important;
    }

    // HACK: we're not seeing the calendar properly autoresize, so override the CSS to force it to do the right thing.
    .fc .fc-scroller {
        > table,
        .fc-daygrid-body,
        .fc-daygrid-body > table {
            width: 100% !important;
        }
    }

    label {
        display: inline !important;
    }

    label:not(:first-of-type) {
        margin-left: 10px;
    }

    .reset-filters {
        display: block;
        margin-bottom: 25px;
    }

    .checkbox {
        display: inline-block;
        margin-right: 20px;
        span {
            display: inline-block;
            margin-left: 5px;
        }
    }
}
