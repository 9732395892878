admin-organization-options {
    .form-inline {
        margin-bottom: 20px;

        .form-group {
            margin-bottom: 15px;
        }

        select,
        input[type="text"] {
            height: 40px !important;
            margin-bottom: 0px !important;
            margin-right: 5px;
        }
    }

    tr.organization {
        cursor: pointer;
    }

    .create {
        margin-left: 5px;
    }
}
