.unlockable-admin-section {
    display: block;
    padding: 10px 0px;
    margin: 0px 12px;
    border: 1px dashed $COLOR_V3_BEIGE_DARKEST;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    &:not(.editing) {
        &:hover {
            background-color: rgba($COLOR_V3_BLUE, 0.2);
            border: 1px solid $COLOR_V3_BLUE;

            &:before {
                color: $COLOR_V3_BLUE;
            }

            hr {
                border-color: $COLOR_V3_BLUE;
            }
        }

        &:before {
            content: "CLICK TO EDIT";
            color: $COLOR_V3_BEIGE_DARKEST;
            position: absolute;
            bottom: 5px;
            right: 5px;
            font-size: 14px;
            text-transform: uppercase;
            display: inline-block;
        }

        a {
            color: $COLOR_V3_CORAL;
            text-decoration: underline;
        }

        .incomplete {
            border-color: $COLOR_V3_RED;
        }

        hr {
            margin: 10px 0;
            background: none;
        }
    }

    &.editing {
        animation: fade-in 0.5s ease-out;
        border: 2px solid $COLOR_V3_BLUE;
        cursor: inherit;

        &:before {
            content: "Editing...";
            display: inline-block;
            position: relative;
            width: 100%;
            height: auto;
            top: -10px;
            margin-bottom: -15px;
            padding: 5px;

            font-size: 16px;
            background-color: $COLOR_V3_BLUE;
            color: $COLOR_V3_WHITE;
            line-height: 1.3em;
            text-align: center;
        }

        hr {
            clear: both;
            background: none;
            padding-top: 20px;
            margin: 0 0 10px;
            border-color: $COLOR_V3_BLUE;
        }
    }
}
